:root {
  /* Default color for archive branding */
  --archive-color: #424242;
  --archive-text: var(--bs-white);
}

.archive-icpsr {
  --archive-color: #020ba9;
  --archive-text: var(--bs-white);
}

.archive-nanda {
  --archive-color: #2f65a7;
  --archive-text: var(--bs-white);
}

.archive-hmca {
  --archive-color: #2e7682;
  --archive-text: var(--bs-white);
}

.archive-nadac {
  --archive-color: #0071bc;
  --archive-text: var(--bs-white);
}

.archive-dsdr {
  --archive-color: #282c67;
  --archive-text: var(--bs-white);
}

.archive-nahdap {
  --archive-color: #004e91;
  --archive-text: var(--bs-white);
}

.archive-nacjd {
  --archive-color: #cb4125;
  --archive-text: var(--bs-white);
}

.archive-sbeccc {
  --archive-color: #17418b;
  --archive-text: var(--bs-white);
}

.archive-pcodr {
  --archive-color: #245a61;
  --archive-text: var(--bs-white);
}

.archive-nacda {
  --archive-color: #152178;
  --archive-text: var(--bs-white);
}

.archive-cfda {
  --archive-color: #020f76;
  --archive-text: var(--bs-white);
}

.archive-aera {
  --archive-color: #5d504d;
  --archive-text: var(--bs-white);
}

.archive-aea {
  --archive-color: #601331;
  --archive-text: var(--bs-white);
}

.archive-weai {
  --archive-color: #004f75;
  --archive-text: var(--bs-white);
}

.archive-jeh {
  --archive-color: #d32c48;
  --archive-text: var(--bs-white);
}

.archive-psid {
  --archive-color: #00274c;
  --archive-text: var(--bs-white);
}

.archive-cghe {
  --archive-color: #00274c;
  --archive-text: var(--bs-white);
}

.archive-somar {
  --archive-color: #2b74a9;
  --archive-text: var(--bs-white);
}

.archive-datalumos {
  --archive-color: #063663;
  --archive-text: var(--bs-white);
}
